import map from 'lodash/map';
import flow from 'lodash/flow';
import merge from 'lodash/merge';

export const getUpdatedOrdersList = (reorderList) => {
  const orders = map(reorderList, 'order');

  const updateNames = flow([
    (orders) => map(orders, 'items'),
    (orderItems) =>
      map(orderItems, (item) => {
        let orderNames = '';
        map(item, (itemInfo, key: number) => {
          const isLastItem = item.length - 1 === key;
          const { name, quantity } = itemInfo;
          orderNames += `${name}${quantity > 1 ? ` (${quantity})` : ''}${!isLastItem ? ', ' : ''} `;
          return item;
        });
        return {
          orderNames,
        };
      }),
  ])(orders);

  return merge(orders, updateNames);
};
