import React from 'react';
import { MotionBox } from '@olo-web/components/atoms/motion/MotionBox.ui';
import { Text } from '@chakra-ui/react';
import { useMenuState } from '@olo-web/client-state';
import { EmptyBagImage } from '@olo-web/assets/images/EmptyBagImage.ui';

export function EmptySearchState(): JSX.Element {
  const { searchText } = useMenuState();
  return (
    <MotionBox
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      display="flex"
      justifyItems="center"
      alignItems="center"
      flexDir="column"
      pb={12}
      pt={4}
      w="100%"
    >
      <EmptyBagImage w="120px" h="150px" />
      <Text textAlign="center" mt={4} data-testid="unable-to-find-item-text">
        We could not find an item related to <b>{searchText}</b>
      </Text>
    </MotionBox>
  );
}
