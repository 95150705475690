import React from 'react';
import { BoxProps, Tag } from '@chakra-ui/react';

export const OutOfStockTag = (props: BoxProps) => (
  <Tag
    bg="blackAlpha.50"
    color="blackAlpha.400"
    fontSize="xs"
    whiteSpace="nowrap"
    w="90px"
    display="flex"
    alignItems="center"
    justifyContent="center"
    {...props}
    data-testid="out-of-stock-tag"
  >
    Out of stock
  </Tag>
);
