/**
 * This component contains all the custom hooks called in MenuLayout for performance optimization.
 * If they are in the MenuLayout component, a state update in one of these hooks will cause the MenuLayout component to rerender, which is very expensive since it is the page component.
 * And since these custom hooks contain thousands of hooks as a whole since they are very deeply nested trees of hooks, it is very likely to cause a lot of expensive rerenders.
 * THe hooks themselves can be very expensive to run, especially useInitializeCustomerSession and useOrderTimeChangeToast.
 * Isolating them to separate components ensures that they can't trigger each other to rerun.
 */

import React, { useState, Dispatch, SetStateAction } from 'react';
import { useOrderTimeChangeToast, useUpdateUrlIfEmptySlug } from '@olo-web/utils/common/hooks';
import { useInitializeCustomerSession } from '@olo-web/domain/customer';
import { useUnavailabilityAlert } from '@domain/merchants/hooks/useUnavailabilityAlert';
import { useRemoveInvalidAddress } from '@olo-web/domain/orders/hooks/useRemoveInvalidAddress';

const OrderTimeChangeHook = () => {
  useOrderTimeChangeToast(); // TODO going to be removed with asap server epic
  return null;
};
const InitializeCustomerSessionHook = ({
  setHasInitialized,
}: {
  setHasInitialized: Dispatch<SetStateAction<boolean>>;
}) => {
  useInitializeCustomerSession(setHasInitialized);
  return null;
};
const UnavailabilityAlert = () => {
  useUnavailabilityAlert();
  return null;
};

const UpdateUrlIfEmptySlug = () => {
  useUpdateUrlIfEmptySlug();
  return null;
};
const RemoveInvalidAddress = () => {
  useRemoveInvalidAddress();
  return null;
};
export const MenuLayoutHooks = () => {
  const [hasInitialized, setHasInitialized] = useState(false);

  return (
    <>
      <OrderTimeChangeHook />
      {!hasInitialized && <InitializeCustomerSessionHook setHasInitialized={setHasInitialized} />}
      <UnavailabilityAlert />
      <UpdateUrlIfEmptySlug />
      <RemoveInvalidAddress />
    </>
  );
};
