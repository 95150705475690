import { Box } from '@chakra-ui/react';
import Image from 'next/image';
import React from 'react';
import { useMenuHeader } from '../../hooks';
import { useMerchantDisplayName } from '@domain/merchants/hooks/useMerchantDisplayName';

export const MenuHeaderBgImage = () => {
  const { bg, backgroundUrl } = useMenuHeader();
  const merchantName = useMerchantDisplayName();

  return (
    <>
      <Image src={backgroundUrl} fill alt={`${merchantName} background image`} objectFit="cover" />
      <Box
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: bg.bg,
        }}
      />
    </>
  );
};
