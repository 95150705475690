import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const BagWithPlusIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M11.1536 7.44434H2.84638C2.32497 7.44434 1.89107 7.84496 1.84956 8.36471L1.17248 16.8406C1.07953 18.0043 1.99878 18.9999 3.16613 18.9999H10.8339C12.0012 18.9999 12.9205 18.0043 12.8275 16.8406L12.1504 8.36471C12.1089 7.84496 11.675 7.44434 11.1536 7.44434Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.42822 9.22222V5.56431C4.42822 4.88421 4.69914 4.23197 5.18138 3.75107C5.66361 3.27017 6.31767 3 6.99965 3C7.68164 3 8.33569 3.27017 8.81793 3.75107C9.30016 4.23197 9.57108 4.88421 9.57108 5.56431V9.22222"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16 1V7"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 4H19"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
