import React, { FC, memo } from 'react';
import Image from 'next/image';
import useMenuItemCard from './hooks';
import { Box, Text, Flex, Skeleton, VisuallyHidden } from '@chakra-ui/react';
import { OutOfStockTag } from '@olo-web/components/atoms/OutOfStockTag';
import { formatPrice } from '@olo-web/domain/orders/functions/formatPrice';
import { menuItemImageLoader } from '@olo-web/utils/common/functions';

interface MenuItemCardProps {
  item: IMenuItem;
  variant: string;
  groupName?: string;
  isLoading?: boolean;
}

const MenuItemCard: FC<MenuItemCardProps> = memo(({ item, variant, groupName, isLoading }) => {
  const { handleClick, styles, isMdDown, isSuccessAddedItem, setIsSuccessAddedItem } =
    useMenuItemCard({
      item,
      variant,
      groupName,
    });

  const hasPrice = Number(item?.price ?? 0) > 0;

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      if (item.inStock) {
        handleClick();
      }
    }
  };

  if (isLoading) return <Skeleton w="100%" h="136px" mr={{ base: 0, sm: 6 }} />;

  return (
    <Flex
      {...styles.container}
      _hover={
        !isMdDown
          ? {
              cursor: !item.inStock ? 'not-allowed' : 'pointer',
              boxShadow: 'outline',
            }
          : 'none'
      }
      _focus={{
        outline: '2px solid',
        outlineColor: 'primary.350',
        outlineOffset: '2px',
      }}
      role={isSuccessAddedItem ? 'status' : 'button'}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      justify="space-between"
      bg="white"
      data-testid="menu-item-card"
      onBlur={() => setIsSuccessAddedItem(false)}
      aria-disabled={!item.inStock}
    >
      {isSuccessAddedItem && (
        <VisuallyHidden role="status" aria-live="polite">
          Item added to order
        </VisuallyHidden>
      )}
      <Flex {...styles.textContainer}>
        <Flex flexDir="column">
          <Text
            {...styles?.name}
            data-testid="menu-item-card-name"
            aria-hidden={isSuccessAddedItem}
          >
            {item?.name}
          </Text>
          <Text
            {...styles.description}
            data-testid="menu-item-card-description"
            aria-hidden={isSuccessAddedItem}
          >
            {item.description}
          </Text>
        </Flex>
        <Flex align="center" mt={{ base: 2, sm: 1 }}>
          <Text
            {...styles.price}
            data-testid="menu-item-card-price"
            aria-hidden={isSuccessAddedItem}
          >
            {hasPrice ? formatPrice(item?.price) : ''}
          </Text>
          {!item.inStock && <OutOfStockTag ml={3} />}
        </Flex>
      </Flex>
      {item?.imageUrl && (
        <Box
          {...styles.image}
          data-testid="menu-item-card-image"
          opacity={!item.inStock ? 0.6 : 1}
          position="relative"
          aria-hidden={isSuccessAddedItem}
        >
          <Image
            src={item.imageUrl}
            loader={menuItemImageLoader}
            sizes={'(min-width: 768px) 136px, 104px'}
            fill
            objectFit="cover"
            alt={`image of ${item?.name}`}
          />
        </Box>
      )}
    </Flex>
  );
});

export default MenuItemCard;
