import { useCallback, useMemo, useState } from 'react';
import { variantStyles } from '../MenuItemCard.styles';
import { EModalTypes } from '@olo-web/types/enums';
import { useModalDispatch, useScreenSizeState } from '@olo-web/client-state';

interface UseMenuItemCardProps {
  item: IMenuItem;
  variant: string;
  groupName?: string;
}

interface UseMenuItemCardResult {
  handleClick: () => void;
  styles: any;
  isMdDown: boolean;
  isSuccessAddedItem: boolean;
  setIsSuccessAddedItem: (value: boolean) => void;
  onSuccess: () => void;
}

const useMenuItemCard = ({
  item,
  variant,
  groupName,
}: UseMenuItemCardProps): UseMenuItemCardResult => {
  const modalDispatch = useModalDispatch();
  const { isMdDown } = useScreenSizeState();
  const [isSuccessAddedItem, setIsSuccessAddedItem] = useState(false);
  const styles = useMemo(() => {
    return variantStyles(variant);
  }, [variant]);

  const onSuccess = () => {
    setIsSuccessAddedItem(true);
  };

  const handleClick = useCallback(() => {
    if (!item.inStock) return;
    const payload = {
      modalKey: EModalTypes.ITEM_DETAILS,
      modalContext: { item, groupName, onSuccess },
    };
    modalDispatch({ type: 'OPEN_MODAL', payload });
  }, [item, modalDispatch, groupName]);

  return {
    handleClick,
    styles,
    isMdDown,
    isSuccessAddedItem,
    setIsSuccessAddedItem,
    onSuccess,
  };
};

export default useMenuItemCard;
