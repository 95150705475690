const commonStyles = {
  container: {
    display: 'flex',
    borderRadius: 4,
    boxShadow: {
      base: 'none',
      sm: 'base',
    },
    transition: '0.2s',
    overflow: 'hidden',
    w: '100%',
  },
  textContainer: {
    overflow: 'hidden',
    pl: { base: 0, sm: 4 },
    pr: 4,
    py: { base: 0, sm: 4 },
    display: 'flex',
    flexDir: 'column',
    justify: { base: 'flex-start', sm: 'space-between' },
    flex: 1,
  },
  image: {
    bgPosition: 'center',
    bgSize: 'cover',
    borderRadius: { base: 4, sm: 0 },
  },
  name: {
    noOfLines: 2,
    textStyle: 'h4',
    color: 'blackAlpha.500',
    lineHeight: '18px',
  },
  description: {
    color: 'blackAlpha.350',
    noOfLines: 2,
    textStyle: 'p',
    lineHeight: '17px',
    mt: 1,
  },
  price: {
    color: 'blackAlpha.500',
    textStyle: 'h4',
    fontWeight: '400',
  },
};

const verticalStyles = {
  ...commonStyles,
  container: {
    ...commonStyles.container,
    flexDir: 'column',
    h: 225,
  },
  image: {
    ...commonStyles.image,
    w: '100%',
    h: '125px',
  },
};

const horizontalStyles = {
  ...commonStyles,
  container: {
    ...commonStyles.container,
    h: { base: 'auto', sm: 136 },
  },
  image: {
    ...commonStyles.image,
    w: { base: 104, sm: 136 },
    h: { base: 104, sm: '100%' },
  },
};

export const variantStyles = (variant: string): any => {
  switch (variant) {
    case 'vertical':
      return verticalStyles;
    default:
      return horizontalStyles;
  }
};
