import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { GoToLogo as GoToLogoUi } from '@olo-web/assets/images/GoToLogo.ui';
import { useMerchantIdentification } from '@olo-web/domain/merchants/queries/useMerchantIdentification';
export const GoToBanner = () => {
  const { data: merchantIdentification } = useMerchantIdentification();
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isAndroid = /Android/i.test(navigator.userAgent);
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      padding="1rem"
      backgroundColor="gray.100"
      borderRadius="md"
      boxShadow="md"
    >
      <Box flex="0 0 auto">
        <GoToLogoUi w="56px" h="56px" />
      </Box>
      <Text
        flex="1"
        textAlign="left"
        fontSize="var(--font-size-100, 16px)"
        fontWeight="600"
        mx="2rem"
      >
        Download for a quicker way to reorder
      </Text>
      <Box flex="0 0 auto">
        <Button
          background="#0D0A2E"
          size="md"
          height="30px"
          borderRadius="var(--Radius-radius-full, 96px)"
          onClick={async () => {
            try {
              await navigator.clipboard.writeText(
                merchantIdentification?.business_location_id || ''
              );
            } catch (e) {
              console.error('Failed to copy: ', e);
            }
            if (isIOS) {
              window.open(`https://apps.apple.com/us/app/goto-by-spoton/id6736528808`, '_blank');
            } else if (isAndroid) {
              window.open(
                `https://play.google.com/store/apps/details?id=com.spoton.gotobyspoton`,
                '_blank'
              );
            }
          }}
        >
          Open
        </Button>
      </Box>
    </Flex>
  );
};
