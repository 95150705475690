import React, { FC, useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { ButtonGroup, Button, Icon, ButtonProps } from '@chakra-ui/react';
import { BagWithPlusIcon } from '@olo-web/assets/icons/BagWithPlusItem.ui';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useReorderItems } from '@olo-web/domain/orders/mutations/useReorderItems';
import { useMerchantPath } from '@domain/merchants/hooks/useMerchantPath';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { OrderIcon } from '@olo-web/assets/icons/OrderIcon.ui';
import { useBagBar } from '../../../Bag/hooks/useBagBar';
import { useSendEvent, useToast } from '@olo-web/utils/common/hooks';
import { EAnalyticsEventNames } from '@olo-web/types/enums';

interface IOrderAgainButtonsProps {
  previousOrders: any;
}

const commonButtonStyles: ButtonProps = {
  w: '100%',
  borderRadius: { base: 30, sm: 0 },
  bg: { base: 'blackAlpha.100', sm: 'none' },
  _hover: {
    bg: { base: 'blackAlpha.200', sm: 'blackAlpha.50' },
  },
  _active: {
    bg: { base: 'blackAlpha.300', sm: 'blackAlpha.100' },
  },
  color: 'blackAlpha.900',
  textDecor: { base: 'underline', sm: 'none' },
};

export const MenuOrderAgainButtons: FC<IOrderAgainButtonsProps> = ({ previousOrders }) => {
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const router = useRouter();
  const merchantPath = useMerchantPath();
  const [isLoading, setIsLoading] = useState(false);
  const { notify } = useToast();
  const { mutateAsync: reorderItems, isLoading: reorderingItems } = useReorderItems({
    onError: (error) => {
      notify({
        status: 'error',
        title: 'Unable to add all items',
        description: error?.message || 'Please try again',
        position: 'top',
        variant: 'left-accent',
      });
    },
  });

  const { sendEvent } = useSendEvent({ canRepeat: true });

  const { handleOpen: handleOpenBagBar } = useBagBar();

  const handleViewOrderClick = useCallback(() => {
    router.push(`${merchantPath}/order/${previousOrders?.id}`, undefined, {
      shallow: true,
    });
  }, [router, merchantPath, previousOrders?.id]);

  const addItems = async () => {
    try {
      await reorderItems({
        merchantId: merchant?.merchantId,
        orderId: order?.id,
        orderTypeId: order?.orderTypeId,
        oldOrderId: previousOrders?.id,
      } as IReorderItemsProps);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const handleAddToBagClick = async () => {
    try {
      setIsLoading(true);
      sendEvent(EAnalyticsEventNames.QUICK_REORDER);
      await addItems();
    } catch (err) {
      console.error(err);
    } finally {
      handleOpenBagBar();
      setIsLoading(false);
    }
  };

  return (
    <ButtonGroup
      w="100%"
      spacing={{ base: 4, sm: 0 }}
      borderTopRadius="0"
      borderTop={{ base: 'none', sm: '1px solid' }}
      borderTopColor={{ base: 'none', sm: 'blackAlpha.100' }}
    >
      <Button
        {...commonButtonStyles}
        borderRight={{ base: 'none', sm: '1px solid' }}
        borderRightColor={{ base: 'none', sm: 'blackAlpha.100' }}
        rightIcon={<Icon as={OrderIcon} h={5} w={5} ml={1} />}
        onClick={handleViewOrderClick}
        data-testid="view-reorder-button"
        role="link"
      >
        View order
      </Button>

      <Button
        {...commonButtonStyles}
        rightIcon={<Icon as={BagWithPlusIcon} h={5} w={5} ml={1} />}
        onClick={handleAddToBagClick}
        isLoading={reorderingItems || isLoading}
        loadingText="Adding..."
        borderBottom={{ base: 'none', sm: '2px solid' }}
        borderBottomColor={{ base: 'none', sm: 'primary.350' }}
        data-testid="add-reorder-to-bag-button"
      >
        Add to bag
      </Button>
    </ButtonGroup>
  );
};
