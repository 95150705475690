import React, { useMemo, FC } from 'react';
import { GridItem, StackDivider, VStack } from '@chakra-ui/react';
import MenuItemCard from '../../MenuItemCard';

const col = (cols: number) => `repeat(${cols}, 1fr)`;

type T = {
  recommenededItems?: IMenuItem[];
  isLoading: boolean;
};

export const AIRecommendedItemsBody: FC<T> = ({ recommenededItems, isLoading }) => {
  const itemsToRender = useMemo(() => {
    if (isLoading) {
      const randomNumber = Math.round(Math.random() * 9999999999999);
      return new Array(5).fill(true).map((_, index) => ({
        id: `loading-item-card-${randomNumber + index}`,
      }));
    }
    return recommenededItems || [];
  }, [isLoading, recommenededItems]);

  return (
    <VStack
      display={{ base: 'flex', sm: 'grid' }}
      flexDirection="column"
      gridTemplateColumns={{
        sm: col(2),
        md: col(3),
        xl: col(4),
      }}
      data-testid="menu-item-ai-suggested-items"
      w="100%"
      gap={{ base: 0, sm: 6 }}
      spacing={4}
      divider={<StackDivider display={{ base: 'block', sm: 'none' }} />}
      px={4}
    >
      {itemsToRender?.map((item: IMenuItem) => (
        <GridItem w={{ base: '100%', md: 'auto' }} key={item?.id} colSpan={1}>
          <MenuItemCard
            item={item}
            variant="horizontal"
            groupName="Picked for you"
            isLoading={isLoading}
          />
        </GridItem>
      ))}
    </VStack>
  );
};
