import { useEffect } from 'react';
import { useValidateDeliveryAddress } from '../queries/useValidateDeliveryAddress';
import { useDeliveryAddressDispatch, useModalState } from '@olo-web/client-state';
import { useEstimateDelivery } from '@olo-web/domain/deliveryService/queries/useEstimateDelivery';
import { useIsThirdPartyDelivery } from '@olo-web/utils/common/hooks';

export const useRemoveInvalidAddress = () => {
  const { data: validateDeliveryAddress, isIdle, isLoading } = useValidateDeliveryAddress();
  const { modalKey } = useModalState();
  const isThirdPartyDelivery = useIsThirdPartyDelivery();
  const { isError: isDeliveryEstimateError } = useEstimateDelivery();
  const deliveryAddressDispatch = useDeliveryAddressDispatch();

  useEffect(() => {
    if (
      ((isThirdPartyDelivery && isDeliveryEstimateError) ||
        (!isThirdPartyDelivery &&
          !isIdle &&
          !isLoading &&
          !validateDeliveryAddress?.withinBounds)) &&
      modalKey === ''
    ) {
      deliveryAddressDispatch({
        type: 'CLEAR_ADDRESS',
      });
    }
  }, [
    deliveryAddressDispatch,
    validateDeliveryAddress,
    validateDeliveryAddress?.withinBounds,
    isIdle,
    isLoading,
    modalKey,
    isThirdPartyDelivery,
    isDeliveryEstimateError,
  ]);
};
