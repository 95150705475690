import React, { useRef, useEffect } from 'react';
import { Box, HStack, StackDivider } from '@chakra-ui/react';
import { Menu } from './Menu.ui';
import { IS_IOS } from '@olo-web/utils/common/constants';
import { BagButtonAndDrawer, BagSideBar } from '../Bag';
import { Footer } from '@olo-web/components/molecules/Footer';
import { useMenuGroupScrollListener } from './common';
import { useInView } from 'react-intersection-observer';
import { useMenuDispatch } from '@olo-web/client-state';
import { DineInUnavailable } from './DineInUnavailable';
import { EOrderTypes } from '@olo-web/types/enums';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useRouter } from 'next/router';
import { MenuLayoutHooks } from './MenuLayoutHooks.ui';
import { useMediaQuery } from '@chakra-ui/react';
import { breakpointsObject } from '@olo-web/theme/breakpoints';

export const MenuLayout = () => {
  const {
    query: { orderType },
  } = useRouter();
  const { data: merchant } = useMerchant();
  const { ref, inView } = useInView();
  const { handleScrollThrottled, groupRefs } = useMenuGroupScrollListener({ footerInView: inView });
  const scrollBoxRef = useRef(null);
  const menuDispatch = useMenuDispatch();
  const [isSmallerThanMed] = useMediaQuery(`(max-width: ${breakpointsObject?.md})`);
  const [isLarge] = useMediaQuery(`(min-width: ${breakpointsObject?.lg})`);
  useEffect(() => {
    if (scrollBoxRef.current) {
      menuDispatch({
        type: 'SET_SCROLL_BOX_REF',
        payload: scrollBoxRef,
      });
    }
  }, [scrollBoxRef, menuDispatch]);

  if (orderType === EOrderTypes.DINE_IN && !merchant?.parsedOrderTypeIds[orderType as string]) {
    return <DineInUnavailable />;
  }

  return (
    <>
      <MenuLayoutHooks />
      <Box
        display="flex"
        flexDir="column"
        pos="fixed"
        top="0"
        left="0"
        right="0"
        bottom="env(safe-area-inset-bottom, 8px)"
      >
        <HStack
          w="100%"
          h="100%"
          // pos relative will keep content from scrolling
          // under button, but may not give us the desired effect
          // from a visual / ui standpoint
          pos="relative"
          pb={{ base: IS_IOS ? 4 : 0, md: 0 }}
          divider={
            <StackDivider
              sx={{
                marginLeft: '0px !important',
                marginRight: '0px !important',
              }}
            />
          }
        >
          <Box
            top="0"
            display="flex"
            flexDir="column"
            overflowY="scroll"
            w={{ md: '100%', lg: 'calc(100% - 360px)' }}
            maxH="100%"
            pos="absolute"
            ref={scrollBoxRef}
            onScroll={handleScrollThrottled}
            width="100%"
          >
            <Menu groupRefs={groupRefs} />
            <Footer id="footer" mt={0} pb={{ base: 10, sm: 2 }} pt={{ base: 8, sm: 0 }} ref={ref} />
          </Box>
          {isLarge && (
            <Box pos="fixed" right={0} bottom={0} top={0} w="360px" bg="white">
              <BagSideBar />
            </Box>
          )}
        </HStack>
        {isSmallerThanMed && <BagButtonAndDrawer />}
      </Box>
    </>
  );
};
