import React, { useRef, useEffect, FC } from 'react';
import { Text, Flex } from '@chakra-ui/react';
import { useMenuState } from '@olo-web/client-state';

type T = {
  group: IMenuGroup;
};

export const MenuGroupHeader: FC<T> = ({ group }) => {
  const { id, name, description } = group;
  const groupNameRef = useRef(null);
  const { selectedGroupId } = useMenuState();
  const isSelected = selectedGroupId === id;

  useEffect(() => {
    if (isSelected) groupNameRef.current.focus({ preventScroll: true });
  }, [isSelected]);
  // The Flex component's id={`group-menu-${id}`} prop is necessary for the menu ribbon scrolling to work
  return (
    <Flex
      flexDir="column"
      mt={5}
      mb={7}
      pl={{ base: 4, sm: 0 }}
      id={`group-menu-${id}`}
      role="region"
      aria-labelledby={`group-heading-${id}`}
    >
      <Text
        ref={groupNameRef}
        textStyle="h4"
        fontSize={{ base: '1.2rem', md: '1.5rem' }}
        color={isSelected ? 'primary.350' : 'blackAlpha.800'}
        _focus={{ outline: '2px solid', outlineColor: 'primary.350', outlineOffset: '2px' }}
        data-testid="menu-item-group-name"
        as="h2"
        id={`group-heading-${id}`}
        tabIndex={-1}
      >
        {name}
      </Text>
      {description && (
        <Text fontSize={{ base: '0.8rem', md: '0.9rem' }} color="blackAlpha.400">
          {description}
        </Text>
      )}
    </Flex>
  );
};
