import {
  Box,
  Center,
  Flex,
  Skeleton,
  StackDivider,
  useUpdateEffect,
  VStack,
} from '@chakra-ui/react';
import { BagStar } from '@olo-web/assets/images/BagStar.ui';
import { OnlineOrderingUnavailable } from '@olo-web/assets/images/OnlineOrderingUnavailable.ui';
import { useGlobalUIState, useModalDispatch } from '@olo-web/client-state';
import { WideScreenContainer } from '@olo-web/components/atoms/WideScreenContainer';
import { useMenuFilteredBySearch } from '@olo-web/domain/menu/queries/index';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { EAnalyticsEventNames, EModalTypes } from '@olo-web/types/enums';
import { useError, useIsDineIn, useSendEvent } from '@olo-web/utils/common/hooks';
import { useAIRecommendationsFlagABStatus } from '@olo-web/utils/common/hooks/useGoogleAISuggestionsStatus';
import { useIsOrderTimeToday } from '@olo-web/utils/common/hooks/useIsOrderTimeToday';
import React, { memo, MutableRefObject, useEffect, useState } from 'react';
import { OrderingUnavailableAlert } from './common';
import { AIRecommendations } from './AIRecommendations/AIRecommendations.ui';
import { EmptySearchState, MenuGroupHeader, MenuGroupItems } from './MenuGroups';
import { MenuHeader } from './MenuHeader';
import { useMenuGroupRibbon } from './MenuHeader/common';
import { MenuMetaHeader } from './MenuMetaHeader';
import { MenuOrderAgain } from './MenuOrderAgain';
import { GoToBanner } from './GoToBanner';
import { useFeatureFlags } from '@olo-web/domain/featureFlags/queries';
import { isFeatureEnabledForMerchant } from '@olo-web/utils/common/functions';
import { useMerchant } from '@olo-web/domain/merchants/queries/useMerchant';
const MenuLoading = () => {
  const emptyGroups = new Array(3).fill(true);
  const randomNumber = Math.random() * 9999999999;

  return (
    <>
      {emptyGroups.map((_, index) => (
        <Flex
          key={`loading-group-${randomNumber + index}`}
          flexDir="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          mt={8}
          w="100%"
        >
          <Skeleton h={10} w="200px" mb={8} />
          <MenuGroupItems isLoading={true} />
        </Flex>
      ))}
    </>
  );
};

interface Props {
  groupRefs: MutableRefObject<Record<string, HTMLElement>>;
}

function MenuComponent({ groupRefs }: Props): JSX.Element {
  const callError = useError();
  const { groups, error, searchText, isLoading, isIdle } = useMenuFilteredBySearch();
  const { isLoading: isOrderLoading } = useOrder();
  const { sendEvent } = useSendEvent({ canRepeat: true });
  const { handleMenuRibbonFocus } = useMenuGroupRibbon();
  const { bagOpen } = useGlobalUIState();
  const isDineIn = useIsDineIn();
  const { isGoogleAIABTestingEnabled, showInMenuAndBeforeCheckout, showInMenu } =
    useAIRecommendationsFlagABStatus();
  const showMenuRecommendations =
    isGoogleAIABTestingEnabled && (showInMenuAndBeforeCheckout || showInMenu) && !isDineIn;
  const modalDispatch = useModalDispatch();
  const isOrderTimeToday = useIsOrderTimeToday();
  const { data: featureFlags } = useFeatureFlags();
  const { data: merchant } = useMerchant();
  const goToBannerFeatureFlagStatus = isFeatureEnabledForMerchant({
    merchantId: merchant?.merchantId,
    featureEnabled: featureFlags?.showGoToBanner?.on,
    allowList: featureFlags?.showGoToBanner?.allow,
    denyList: featureFlags?.showGoToBanner?.deny,
  });
  const androidGoToBannerStatus = isFeatureEnabledForMerchant({
    merchantId: merchant?.merchantId,
    featureEnabled: featureFlags?.androidGoToBannerStatus?.on,
    allowList: featureFlags?.androidGoToBannerStatus?.allow,
    denyList: featureFlags?.androidGoToBannerStatus?.deny,
  });
  const [showGoToBanner, setShowGoToBanner] = useState(false);
  useEffect(() => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    if (isAndroid) {
      setShowGoToBanner(goToBannerFeatureFlagStatus && androidGoToBannerStatus);
    } else if (isIOS) {
      setShowGoToBanner(goToBannerFeatureFlagStatus);
    }
  }, [goToBannerFeatureFlagStatus, androidGoToBannerStatus]);

  useEffect(() => {
    if (!isOrderTimeToday && !isOrderLoading) {
      modalDispatch({
        type: 'OPEN_MODAL',
        payload: {
          modalKey: EModalTypes.CONFIRM_FUTURE_FULFILLMENT_TIME,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOrderLoading]);

  useUpdateEffect(() => {
    const itemCount = groups.reduce((itemCount, menuGroup) => {
      return (itemCount += menuGroup.menuItems.length);
    }, 0);
    sendEvent(EAnalyticsEventNames.MENU_SEARCH, {
      googleAnalytics: {
        menuSearchTerm: searchText,
        menuSearchResultCount: itemCount,
      },
    });
  }, [searchText]);

  useEffect(() => {
    callError({ error });
  }, [callError, error]);

  const captureEsc = (e) => {
    e.preventDefault();
    if (e.key === 'Escape' && e.keyCode === 27 && !bagOpen) {
      handleMenuRibbonFocus();
    }
  };

  return (
    <Flex role="main" flexDir="column" w="100%" bg="base.50" onKeyUp={captureEsc}>
      {/* BagStar component fixes svg star shape in bag component. The component is hidden and user cant see it. */}
      <BagStar width="0" height="0" opacity="0" position="absolute" top="-100" left="-100" />
      <MenuMetaHeader />
      {showGoToBanner && <GoToBanner />}
      <MenuHeader />
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        justify="flex-start"
        align="flex-start"
        pb={{ base: 3, md: 8 }}
        bg={{
          base: 'white',
          sm: 'base.50',
        }}
      >
        <WideScreenContainer minH="calc(100vh - 224px)">
          <VStack
            px={{ base: 0, sm: 4, md: 10 }}
            pt={{ base: 0, md: 8 }}
            display="flex"
            spacing={{ base: 0, sm: 4, md: 6 }}
            flexDir="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            pos="relative"
            w="100%"
            divider={
              <StackDivider h={8} bg="blackAlpha.100" display={{ base: 'block', sm: 'none' }} />
            }
          >
            {(isLoading || isIdle) && !groups?.length ? (
              <MenuLoading />
            ) : !groups?.length && searchText?.length ? (
              <EmptySearchState />
            ) : !groups?.length ? (
              <Center w="100%" flexDir="column" mt={{ base: -6, md: 0 }}>
                <OrderingUnavailableAlert isForMobile />
                <OnlineOrderingUnavailable
                  h="100%"
                  w={['94%', '90%', '86%', '800px']}
                  mt={{ base: 8, md: 24 }}
                />
              </Center>
            ) : (
              <>
                <MenuOrderAgain />
                {showMenuRecommendations && <AIRecommendations />}
                {groups.map((group, index) => (
                  <Box
                    width="100%"
                    key={group.id}
                    ref={(elem) => (groupRefs.current[group.id] = elem)}
                  >
                    <MenuGroupHeader group={group} />
                    <MenuGroupItems group={group} />
                    {index + 1 !== groups.length && (
                      <Box
                        w="100%"
                        h={6}
                        bg="base.50"
                        mt={4}
                        boxShadow="inner"
                        display={{ base: 'block', sm: 'none' }}
                      />
                    )}
                  </Box>
                ))}
              </>
            )}
          </VStack>
        </WideScreenContainer>
      </Flex>
    </Flex>
  );
}

export const Menu = memo(MenuComponent);
