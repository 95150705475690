import { useScreenSizeState } from '@olo-web/client-state';
import { useMemo } from 'react';
import { getUpdatedOrdersList } from '../functions/getUpdatedOrdersList';

export const useReorderListBasedOnScreenSize = (
  reorderList: IPreviousOrder[]
): {
  isShowSeeAllButton: boolean;
  reorderList: IOrderItem[];
} => {
  const { isLgDown, isSm, isMd, isXl } = useScreenSizeState();
  const ordersList = useMemo(() => getUpdatedOrdersList(reorderList.slice(0, 4)), [reorderList]);

  const reorderListLength = reorderList.length;

  if (reorderListLength > 2 && (isSm || isLgDown) && !isMd && !isXl) {
    return {
      isShowSeeAllButton: true,
      reorderList: ordersList.slice(0, 2),
    };
  }
  if (reorderListLength > 3 && isMd && !isXl) {
    return {
      isShowSeeAllButton: true,
      reorderList: ordersList.slice(0, 3),
    };
  }
  if (reorderListLength > 4 && isXl) {
    return {
      isShowSeeAllButton: true,
      reorderList: ordersList.slice(0, 4),
    };
  }

  return {
    isShowSeeAllButton: false,
    reorderList: ordersList,
  };
};
