import React, { useMemo } from 'react';
import Head from 'next/head';
import { useMerchantGroup } from '@domain/merchants/queries/useMerchantGroup';
import { useMenu } from '@olo-web/domain/menu/queries';
import { useMerchant } from '@domain/merchants/queries/useMerchant';

const descBlockers = ['feature', 'recommend', 'favorite'];
const defaultKeywords = [
  'online ordering',
  'restaurant',
  'pickup',
  'delivery',
  'curbside',
  'dine in',
];

export const MenuMetaHeader = () => {
  const { data: group } = useMerchantGroup();
  const { data: menu } = useMenu();
  const { data: merchant } = useMerchant();
  const menuGroupNames = useMemo(() => {
    if (!menu) return [];
    return menu.reduce((acc, curr) => {
      const lcName = curr?.name.toLowerCase();
      if (descBlockers.some((b) => lcName.includes(b))) return acc;
      return [...acc, lcName];
    }, []);
  }, [menu]);

  const description = useMemo(() => {
    if (!group) return 'Order delicious food online';
    const names = group?.merchants?.reduce((acc, curr, idx, arr) => {
      const len = arr.length;
      if (idx === 0 && len < 2) return curr?.name;
      if (idx === len - 1) return acc + '& ' + curr?.name;
      return acc + curr?.name + ', ';
    }, '');
    let desc = 'Order';
    if (menuGroupNames?.length) {
      desc += ' ' + menuGroupNames.slice(0, 4).join(', ');
      desc += ' and more';
    }
    if (names?.length) {
      desc += ' from ' + names;
    }
    return desc;
  }, [group, menuGroupNames]);

  const keywords = useMemo(() => {
    const kw = defaultKeywords.slice();
    if (menuGroupNames?.length) {
      menuGroupNames.forEach((n) => kw.unshift(n));
    }
    return kw.join(',');
  }, [menuGroupNames]);

  return (
    <Head>
      <title>{`Order ${merchant?.name || group?.name || 'Online'}`}</title>
      <meta key="og:description" property="og:description" content={description} />
      <meta name="keywords" content={keywords} />
    </Head>
  );
};
