import React, { useEffect, useState } from 'react';
import { VStack, StackDivider, Text } from '@chakra-ui/react';
import { MotionBox } from '@olo-web/components/atoms/motion/MotionBox.ui';
import { AIRecommendedItemsBody } from './AIRecommendationsBody/AIRecommendationsBody.ui';
import { useRecommendedForYou } from '@olo-web/domain/recommendations/queries/useRecommendedForYou';
import {
  ERecommendationStatus,
  ERecommendationType,
  EAnalyticsEventNames,
} from '@olo-web/types/enums';
import { useSendEvent } from '@olo-web/utils/common/hooks';

export const AIRecommendations = () => {
  const { data: recommendations, isLoading } = useRecommendedForYou();
  const { sendEvent } = useSendEvent();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const shortenedRecommendations = recommendations?.slice(0, 6) || [];
  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      const recommendedItems =
        shortenedRecommendations?.map((item, index) => {
          const recItem = {
            itemName: item.name,
            price: item.price,
            displayPosition: index + 1, // Starting displayPosition from 1
          };
          return recItem;
        }) || [];

      const googleAnalytics = {
        recommendedItems: JSON.stringify(recommendedItems),
        fromRecommendedSection: true,
        recommendationStatus: shortenedRecommendations?.length
          ? ERecommendationStatus.DISPLAYED
          : ERecommendationStatus.NO_RESULTS,
        recommendationType: ERecommendationType.MENU,
      };

      sendEvent(EAnalyticsEventNames.RECOMMENDATIONS_LOADED, { googleAnalytics });
    }
  }, []);
  if (!shortenedRecommendations?.length) return null;

  return (
    <MotionBox
      w="100%"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.3 }}
    >
      <VStack align="stretch" py={5} px={{ base: 4, sm: 0 }} spacing={7} width="100%">
        <Text
          textStyle="h4"
          fontSize={{ base: '1.2rem', md: '1.5rem' }}
          color={'primary.350'}
          tabIndex={0}
          w="max-content"
          data-testid="google-ai-recommendations-container"
        >
          Picked For You
        </Text>
        <AIRecommendedItemsBody
          recommenededItems={shortenedRecommendations}
          isLoading={isLoading}
        />
      </VStack>
      <StackDivider h={6} bg="base.50" boxShadow="inner" display={{ base: 'block', sm: 'none' }} />
    </MotionBox>
  );
};
