import React from 'react';
import { useSavedDineInContextState, useSavedDineInContextDispatch } from '@olo-web/client-state';
import {
  MenuGroupRibbon,
  MenuHeaderButtonsDesktop,
  MenuHeaderDetails,
  MenuHeaderLogo,
  ScrollSensitiveNavBar,
  useMenuGroupRibbon,
  useMenuHeader,
} from './common';
import { useMerchantDisplayName, useMerchantImages } from '@domain/merchants/hooks';
import { useIsDineIn } from '@olo-web/utils/common/hooks/useIsDineIn';
import { useIsOrderTypeSelectorHidden } from '@olo-web/theme/hooks';
import { Box, Flex, Heading, Skeleton, useMediaQuery } from '@chakra-ui/react';
import { CustomerOrLogin } from '@olo-web/components/molecules/CustomerOrLogin';
import { Card } from '@olo-web/components/atoms/Card';
import { OrderTypeSelectorHiddenReplacementBanner } from '@olo-web/components/atoms/OrderTypeSelectorHiddenReplacementBanner';
import { WideScreenContainer } from '@olo-web/components/atoms/WideScreenContainer';
import { MenuHeaderBgImage } from './common/components/MenuHeaderBackgroundImage';
import { breakpointsObject } from '@olo-web/theme/breakpoints';

const SP = 2;

export const MenuHeader = () => {
  const { merchant, group, inView, ref, bg } = useMenuHeader();
  const dineInState = useSavedDineInContextState();
  const { logoUrl } = useMerchantImages();
  const isDineIn = useIsDineIn();
  const merchantName = useMerchantDisplayName();
  const showJoinOrderButton = isDineIn && !dineInState;
  const savedDineInDispatch = useSavedDineInContextDispatch();
  const [isSmallerThanMed] = useMediaQuery(`(max-width: ${breakpointsObject?.md})`);

  const { groupRibbonItems } = useMenuGroupRibbon();
  const isOrderTypeSelectionHidden = useIsOrderTypeSelectorHidden();
  const isLoading = !merchant || !group;
  const isQRClosed = isDineIn && merchant?.qrclosed;

  if (dineInState?.guest?.groupOrderBalancePaidFlag) {
    savedDineInDispatch({
      type: 'RESET',
    });
  }

  return (
    <>
      <Box
        display={{ base: 'flex', md: 'block' }}
        flexDir="column"
        w="100%"
        pos="relative"
        justifyContent="stretch"
        alignItems="stretch"
        overflow={{ base: 'hidden', md: 'visible' }}
        background={{ md: 'white' }}
      >
        <WideScreenContainer data-testid="header-section">
          <Flex pt={{ base: 0, md: 8 }} pb={{ base: 0, md: 4 }} px={{ base: 0, md: 10 }}>
            <ScrollSensitiveNavBar show={!inView} />
            <Flex
              justify={{ base: 'center', md: 'stretch' }}
              align={{ base: 'center', md: 'flex-start' }}
              flexDir={{ base: 'column', md: 'row' }}
              w="100%"
              pt={{ base: 'calc(8px + env(safe-area-inset-top))', md: 0 }}
              {...bg}
              bg={{ base: bg?.bg, md: 'none' }}
              bgGradient={{ base: bg?.bgGradient, md: 'none' }}
              pb={isQRClosed ? 8 : 0}
            >
              <Box
                pos="relative"
                w="100%"
                textAlign="right"
                mb="-44px"
                display={{ md: 'none', base: 'block' }}
                zIndex="3"
              >
                <CustomerOrLogin
                  buttonProps={{
                    colorScheme: 'whiteAlpha',
                    ...(isSmallerThanMed && { 'data-testid': 'login-button' }),
                  }}
                  avatarMenuButtonProps={{
                    mt: 2,
                    mr: 3,
                    color: 'white',
                    ...(isSmallerThanMed && { 'data-testid': 'user-avatar-button' }),
                  }}
                  avatarProps={{ bg: 'whiteAlpha.900', color: 'blackAlpha.900' }}
                />
              </Box>
              {isLoading && (
                <Skeleton
                  variant="rect"
                  h={{ base: '96px', md: '160px' }}
                  w={{ base: '96px', md: '240px', lg: '320px' }}
                  mr={{ md: 8 }}
                  borderRadius={4}
                />
              )}
              {!isLoading && !!logoUrl && (
                <Flex
                  justify="center"
                  align="center"
                  flexDir="column"
                  h={{ md: '160px' }}
                  w={{ base: 'auto', md: '242px', lg: '320px' }}
                  mr={{ md: 8 }}
                  mt={{ base: SP * 5, md: 0 }}
                  mb={{ base: SP, md: 0 }}
                  borderRadius={{ md: 4 }}
                  pos={{ md: 'relative' }}
                  {...bg}
                >
                  {bg?.bg && <MenuHeaderBgImage />}
                  <MenuHeaderLogo src={logoUrl} alt={merchantName} />
                  {isOrderTypeSelectionHidden && (
                    <OrderTypeSelectorHiddenReplacementBanner
                      id="main-content"
                      pos="absolute"
                      bottom={-3}
                      borderBottomRadius="md"
                      display={{ base: 'none', md: 'flex' }}
                    />
                  )}
                </Flex>
              )}
              <Flex
                flexDir="column"
                flex={1}
                width="100%"
                alignItems="center"
                ref={isQRClosed ? ref : null}
                id="main-content"
              >
                <Flex justify="space-between" align="flex-start" w="100%">
                  {isLoading ? (
                    <Skeleton w={{ base: '300px', md: '500px' }} h={{ base: '24px', md: '40px' }} />
                  ) : (
                    <Heading
                      as="h1"
                      fontSize={{ base: 24, md: 30 }}
                      fontWeight="600"
                      color={{ base: 'white', md: 'blackAlpha.500' }}
                      mb={{ base: SP, md: '0' }}
                      mt={{ base: logoUrl ? SP : SP * 4, md: '0' }}
                      px={{ base: 2, md: 0 }}
                      textAlign={{ base: 'center', md: 'left' }}
                      lineHeight={{ base: '28px', md: '45px' }}
                      flex={1}
                      data-testid="location-name"
                      noOfLines={2}
                      zIndex="2"
                    >
                      {merchantName}
                    </Heading>
                  )}
                  <MenuHeaderButtonsDesktop show={inView} display={{ base: 'none', md: 'flex' }} />
                </Flex>
                <Card
                  id="header-card"
                  m={{ base: SP, md: 0 }}
                  p={0}
                  zIndex={1}
                  w={{ base: 'calc(100% - 2rem)', md: '100%' }}
                  boxShadow={{ base: 'md', sm: 'none' }}
                  borderRadius={{ base: isOrderTypeSelectionHidden ? 'lg' : 4, md: 4 }}
                  _hover={{
                    boxShadow: 'none',
                  }}
                  display={isQRClosed && 'none'}
                  sx={
                    showJoinOrderButton && {
                      minHeight: { base: '76px', md: '0' },
                      justifyContent: { base: 'center', md: 'flex-start' },
                      alignItems: 'center',
                      marginBottom: { base: 6, md: 0 },
                      padding: { base: 4, md: 0 },
                    }
                  }
                >
                  <MenuHeaderDetails ref={isQRClosed ? null : ref} />
                </Card>
                {isQRClosed || (
                  <Box
                    h={showJoinOrderButton ? '76px' : '112px'}
                    mt={showJoinOrderButton ? '-80px' : '-100px'}
                    w="120%"
                    bgColor="base.50"
                    borderRadius="500px 500px 0 0"
                    display={{ md: 'none' }}
                    zIndex="0"
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
        </WideScreenContainer>
      </Box>
      {groupRibbonItems.length > 1 && (
        <Box
          pos="sticky"
          top="63px"
          w="100%"
          h="44px"
          bg="white"
          zIndex={inView ? 1 : 3}
          borderTop="1px solid"
          borderBottom="1px solid"
          boxShadow={!inView ? 'base' : 'none'}
          borderColor={inView ? 'blackAlpha.100' : 'transparent'}
          display={{ base: 'none', md: 'block' }}
        >
          <MenuGroupRibbon />
        </Box>
      )}
    </>
  );
};
