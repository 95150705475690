import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { EDateTimeFormat } from '@olo-web/types/enums';

dayjs.extend(LocalizedFormat);
dayjs.extend(utc);

export const formatHistoricalOrderDate = (time: string): string => {
  return dayjs().isSame(dayjs.utc(time), 'year')
    ? dayjs.utc(time).format(EDateTimeFormat.LONG_DAY_MONTH)
    : dayjs.utc(time).format(EDateTimeFormat.DATE_MONTH_YEAR);
};
