import React from 'react';
import { Flex, Text, Heading } from '@chakra-ui/react';

import { OnlineOrderingUnavailable } from '@olo-web/assets/images/OnlineOrderingUnavailable.ui';

export const DineInUnavailable = () => {
  return (
    <Flex alignItems="center" justifyContent="center" flexDirection="column" h="100%">
      <OnlineOrderingUnavailable
        h={{ base: '300px', sm: '400px', md: '500px', lg: '600px' }}
        w={{ base: '300px', sm: '400px', md: '500px', lg: '600px' }}
      />
      <Heading fontWeight={600} as="h1" mb={4}>
        Dine-in unavailable
      </Heading>
      <Text>Please try again later.</Text>
    </Flex>
  );
};
