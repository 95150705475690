import { useCustomer } from '@domain/customer';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { UseQueryResult, useQuery, UseQueryOptions } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useIsDineIn, useSendEvent } from '@olo-web/utils/common/hooks';
import { useMenu } from '@olo-web/domain';
import { useAIRecommendationsFlagABStatus } from '@olo-web/utils/common/hooks/useGoogleAISuggestionsStatus';
import { mockRecommendationsReturn } from '@olo-web/utils/common/functions/mockRecommendationsReturn';
import gen from 'random-seed';
import {
  EAnalyticsEventNames,
  ERecommendationStatus,
  ERecommendationType,
} from '@olo-web/types/enums';
import { filterRecommendedItems } from '@olo-web/utils/common/functions/filterRecommendedItems';
import { useMerchantIdentification } from '@domain/merchants/queries/useMerchantIdentification';
import { useGlobalUIState } from '@olo-web/client-state';
import { validateBlid } from '@olo-web/utils/common/functions/validateBlid';

const isNotProd = process.env.NEXT_PUBLIC_APP_ENVIRONMENT !== 'prod';
let randomSeed = 0;

export const getRecommendedForYouItems = async (
  merchantId: string,
  customerId: string
): Promise<IRecommendation[]> => {
  try {
    const { data } = await axios.get(
      `/api/merchants/${merchantId}/recommendations/${customerId}/recs`,
      null
    );
    if (isNotProd) {
      randomSeed++;
      return mockRecommendationsReturn;
    }
    return data.recommendations;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const useRecommendedForYou = (
  options?: UseQueryOptions<IRecommendation[], AxiosError, IMenuItem[]>
): UseQueryResult<IMenuItem[], AxiosError> => {
  const { data: merchant } = useMerchant();
  const { data: activeBlidSearch } = useMerchantIdentification(merchant?.merchantId);

  const { data: customer } = useCustomer();
  const { data: menu } = useMenu();
  const isDineIn = useIsDineIn();
  const globalUIState = useGlobalUIState();
  const { showInMenu, showInMenuAndBeforeCheckout, isGoogleAIABTestingEnabled } =
    useAIRecommendationsFlagABStatus();

  const validMerchantId =
    activeBlidSearch?.merchant_id ||
    (validateBlid(merchant?.merchantId) ? '' : merchant?.merchantId);
  const isEnabled =
    !isDineIn &&
    (showInMenu || showInMenuAndBeforeCheckout) &&
    isGoogleAIABTestingEnabled &&
    !!validMerchantId;

  const customerId = globalUIState?.customerId || customer?.customerId;
  const { sendEvent } = useSendEvent();

  return useQuery(
    ['item-recommendations-for-you', customerId],
    () => getRecommendedForYouItems(validMerchantId, customerId),
    {
      staleTime: Infinity, //this should prevent any further API calls
      enabled: isEnabled,
      retry: 1,
      onSuccess: (data) => {
        const recommendedItems = data.map((item, index) => {
          const recItem = {
            itemName: item.name,
            price: item.price,
            displayPosition: index + 1, // Starting displayPosition from 1
          };
          return recItem;
        });
        const googleAnalytics = {
          recommendedItems: JSON.stringify(recommendedItems),
          fromRecommendedSection: true,
          recommendationStatus: data.length
            ? ERecommendationStatus.DISPLAYED
            : ERecommendationStatus.NO_RESULTS,
          recommendationType: ERecommendationType.MENU,
        };

        sendEvent(EAnalyticsEventNames.RECOMMENDATIONS_LOADED, { googleAnalytics });
      },
      onError: (error) => {
        const googleAnalytics = {
          recommendationStatus:
            error.code === 'ECONNABORTED'
              ? ERecommendationStatus.TIMEOUT
              : ERecommendationStatus.ERROR,
          recommendationType: ERecommendationType.MENU,
        };

        sendEvent(EAnalyticsEventNames.RECOMMENDATIONS_LOADED, { googleAnalytics });
      },
      select: (data) => {
        const result: IMenuItem[] = [];
        // This code is necessary for testing the item adding feature on the carousel and sheet in QA/local as the API has only been trained for a select few production merchants
        if (isNotProd) {
          const rand = gen.create(randomSeed);
          for (let i = 0; i < 7; i++) {
            const groupIndex = rand(menu.length);

            const itemIndex = rand(menu[groupIndex].menuItems.length);
            result.push(menu[groupIndex].menuItems[itemIndex]);
          }
          return result;
        }
        //This filters the results so that no unavailable items are shown
        result.push(...filterRecommendedItems(data, menu));
        return result;
      },
      ...options,
    }
  );
};
