import { EAnalyticsEventNames } from '@olo-web/types/enums';
import { useSendEvent, useToast } from '@olo-web/utils/common/hooks';
import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { useUpdateOrderInCache } from '@olo-web/domain/orders/hooks/useUpdateOrderInCache';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { EGALocations } from '@olo-web/types/enums/googleAnalyticsLocations.enum';
import { Stack, Text } from '@chakra-ui/react';
import { serializeToCamelCase } from '@olo-web/utils/common/functions';

const invalidItemsComponent = (invalidItems) => (
  <Stack>
    {invalidItems.map((item) => (
      <Text lineHeight={1} key={item.name}>
        {item.name}
      </Text>
    ))}
  </Stack>
);

export const reorderItems = async ({
  merchantId,
  orderId,
  oldOrderId,
  orderTypeId,
}: IReorderItemsProps): Promise<IOrder> => {
  try {
    const url = `/api/merchants/${merchantId}/orders/${orderId}/reorder`;
    const { data } = await axios.post(url, { oldOrderId, orderTypeId });
    return serializeToCamelCase(data);
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const useReorderItems = (
  options: UseMutationOptions<IOrder, AxiosError, IReorderItemsProps>
) => {
  const { sendEvent } = useSendEvent();
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const { notify } = useToast();
  const updateOrderInCache = useUpdateOrderInCache();

  type TReorderItemsProps = {
    oldOrderId: string;
    isRecommenedItem?: boolean;
  };

  return useMutation(
    (args: TReorderItemsProps) => {
      const commonArgs = {
        ...args,
        merchantId: merchant?.merchantId,
        orderId: order?.id,
        orderTypeId: order?.orderTypeId,
        fromRecommendedSection: false,
      };
      return reorderItems({
        ...commonArgs,
      });
    },
    {
      ...options,
      onSuccess: (data) => {
        updateOrderInCache(data);
        if (data?.requestDetails?.invalidItems.length) {
          notify({
            status: 'error',
            title:
              data?.requestDetails?.invalidItems.length > 1
                ? `Sorry, we can't add ${data?.requestDetails?.invalidItems.length} items to your bag because they're out of stock:`
                : `Sorry, we can't add the following item to your bag because it's out of stock:`,
            description: invalidItemsComponent(data?.requestDetails?.invalidItems),
          });
        }
        const googleAnalytics = {
          ecommerce: {
            menuItems: data?.items ? [...data.items] : null,
          },
        };
        sendEvent(EAnalyticsEventNames.DEFAULT, {
          googleAnalytics: {
            eventInfo: {
              location: EGALocations.ONLINE_ORDER,
              action: 'submit',
              object: EAnalyticsEventNames.ADD_TO_CART,
            },
            eventMetadata: googleAnalytics,
          },
        });
      },
    }
  );
};
