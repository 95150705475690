import React, { FC } from 'react';
import { VStack, Text, Grid, Box, Divider } from '@chakra-ui/react';

import { MenuOrderAgainButtons } from '../MenuOrderAgainButtons';

import { useReorderListBasedOnScreenSize } from '../hooks/useReorderListBasedOnScreenSize';
import { useGetPreviousOrdersFromMerchant } from '@domain/orders/queries/useGetPreviousOrdersFromMerchant';
import { formatHistoricalOrderDate } from '@olo-web/domain/orders/functions/formatHistoricalOrderDate';
const col = (cols: number) => `repeat(${cols}, 1fr)`;

export const MenuOrderAgainBody: FC = () => {
  const { data: previousOrder } = useGetPreviousOrdersFromMerchant();
  const { reorderList } = useReorderListBasedOnScreenSize(previousOrder);

  return (
    <Grid
      templateColumns={{
        sm: col(2),
        md: col(3),
        xl: col(4),
      }}
      w="100%"
      gap={{ base: 2, sm: 6 }}
    >
      {reorderList.map((previousOrders, index) => {
        const { id, orderNames, scheduledDateTime } = previousOrders;
        const orderDateTime = previousOrders?.createdDateTime || previousOrders?.sentDateTime;
        return (
          <>
            <VStack
              key={id}
              align="stretch"
              justify="space-between"
              bg="white"
              w="100%"
              borderRadius={4}
              overflow="hidden"
              spacing={4}
              boxShadow={{ base: 'none', sm: 'md' }}
              pt={{ base: 0, sm: 4 }}
            >
              <Box px={{ base: 0, sm: 4 }}>
                <Text textStyle="h4" color="blackAlpha.600" lineHeight="18px">
                  {formatHistoricalOrderDate(scheduledDateTime ? scheduledDateTime : orderDateTime)}
                </Text>
                <Text
                  textStyle="p"
                  color="blackAlpha.350"
                  overflow="hidden"
                  noOfLines={2}
                  lineHeight="17px"
                  mt={1}
                >
                  {orderNames}
                </Text>
              </Box>
              <MenuOrderAgainButtons previousOrders={previousOrders} />
            </VStack>
            {index !== reorderList.length - 1 && (
              <Divider display={{ base: 'inline-block', sm: 'none' }} my={{ base: 3, sm: 0 }} />
            )}
          </>
        );
      })}
    </Grid>
  );
};
