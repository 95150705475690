import React from 'react';
import { Flex, Text, Button, Icon } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import { ArrowRight } from 'react-feather';
import { useReorderListBasedOnScreenSize } from '../hooks/useReorderListBasedOnScreenSize';
import { useSendEvent } from '@olo-web/utils/common/hooks';
import { useMount } from 'react-use';
import { EAnalyticsEventNames } from '@olo-web/types/enums';
import { useGetPreviousOrdersFromMerchant } from '@olo-web/domain/orders/queries/useGetPreviousOrdersFromMerchant';

export const MenuOrderAgainHeader = () => {
  const { data: previousOrder } = useGetPreviousOrdersFromMerchant();
  const { isShowSeeAllButton } = useReorderListBasedOnScreenSize(previousOrder);
  const { asPath, push } = useRouter();
  const { sendEvent } = useSendEvent();

  useMount(() => {
    sendEvent(EAnalyticsEventNames.QUICK_REORDER_SHOWN);
  });

  const handleSeeAllClick = (e) => {
    sendEvent(EAnalyticsEventNames.GO_TO_ORDER_HISTORY, {
      googleAnalytics: {
        eventTarget: e.target,
      },
    });
    push(`${asPath}/history`);
  };

  return (
    <Flex justify="space-between" align="center">
      <Text
        textStyle="h4"
        fontSize={{ base: '1.2rem', md: '1.5rem' }}
        tabIndex={0}
        w="max-content"
        data-testid="order-again-header"
      >
        Order again
      </Text>
      {isShowSeeAllButton && (
        <Button
          bg="transparent"
          rightIcon={<Icon as={ArrowRight} h={5} w={5} />}
          color="blackAlpha.600"
          variant="ghost"
          onClick={handleSeeAllClick}
          p={{ base: 0 }}
          h="22px"
          data-testid="see-all-button"
          role="link"
        >
          See all
        </Button>
      )}
    </Flex>
  );
};
