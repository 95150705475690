export const filterRecommendedItems = (
  recommendations: IRecommendation[],
  accurateMenuGroups: IMenuGroup[]
) => {
  const filteredMenuItems = new Set();
  recommendations.forEach((recommendedItem) => {
    accurateMenuGroups.some((accurateMenuGroup) => {
      const matchingItem = accurateMenuGroup.menuItems.find((menuItem) => {
        return menuItem.menuItemLocationId === recommendedItem.itemId;
      });
      if (matchingItem) {
        filteredMenuItems.add(matchingItem);
        return true;
      }
      return false;
    });
  });
  return Array.from(filteredMenuItems) as IMenuItem[];
};
