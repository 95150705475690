import React from 'react';
import { VStack, StackDivider } from '@chakra-ui/react';
import { useGetPreviousOrdersFromMerchant } from '@olo-web/domain/orders/queries/useGetPreviousOrdersFromMerchant';
import { MenuOrderAgainBody } from './MenuOrderAgainBody';
import { MenuOrderAgainHeader } from './MenuOrderAgainHeader';
import { MotionBox } from '@olo-web/components/atoms/motion/MotionBox.ui';
import { useIsDineIn } from '@olo-web/utils/common/hooks/useIsDineIn';

export const MenuOrderAgain = () => {
  const { data: previousOrder } = useGetPreviousOrdersFromMerchant();
  const isDineIn = useIsDineIn();

  if (!previousOrder || !previousOrder.length || isDineIn) return null;

  return (
    <MotionBox
      w="100%"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.3 }}
    >
      <VStack align="stretch" py={5} px={{ base: 4, sm: 0 }} spacing={7} width="100%">
        <MenuOrderAgainHeader />
        <MenuOrderAgainBody />
      </VStack>
      <StackDivider h={6} bg="base.50" boxShadow="inner" display={{ base: 'block', sm: 'none' }} />
    </MotionBox>
  );
};
