import axios, { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useQuery, UseQueryResult } from 'react-query';

export const getMerchantIdentification = async (
  merchantId: string
): Promise<IActiveBlidSearchResponse> => {
  try {
    const url = `/api/merchants/${merchantId}/active-blid-search`;
    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export function useMerchantIdentification(
  merchantId?: string
): UseQueryResult<IActiveBlidSearchResponse, AxiosError> {
  const router = useRouter();
  const { merchantId: merchantIdFormUrl } = router.query;
  const merchId = merchantId || merchantIdFormUrl;
  return useQuery(
    [`merchant-identification`, merchId],
    () => getMerchantIdentification(merchId as string),
    {
      staleTime: Infinity, //this will hardly ever change
      enabled: !!merchId,
    }
  );
}
