import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useMemo } from 'react';
import { useHandleAsapLogicOnServer } from './useHandleAsapLogicOnServer';

dayjs.extend(utc);
dayjs.extend(timezone);

export const useIsOrderTimeToday = (orderTime?: string): boolean => {
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const handleAsapLogicOnServer = useHandleAsapLogicOnServer();

  const isToday = useMemo(() => {
    if (!merchant || !order) {
      return true;
    }

    const useAsapFulfillmentLeadTime = !!order.asapFulfillmentLeadTime && handleAsapLogicOnServer;

    if (!orderTime && !useAsapFulfillmentLeadTime) {
      return true;
    }

    const now = dayjs().utc().tz(merchant.locationTimezone);

    const orderTimeDate = useAsapFulfillmentLeadTime
      ? now.add(order.asapFulfillmentLeadTime, 'minute')
      : dayjs(orderTime).utc().tz(merchant.locationTimezone);

    return orderTimeDate.isSame(now, 'day');
  }, [orderTime, merchant, handleAsapLogicOnServer, order]);

  return isToday;
};
